import React, { useState, useEffect } from 'react'
import { HashRouter, Routes, Route } from "react-router-dom"
import Button from '@material-ui/core/Button'
import { TextField, InputLabel, MenuItem, Select } from '@material-ui/core'
import Compare from './Compare'
import './App.css'

const MESSAGES = {
  'activated': 'Powiadomienie zostało aktywowane!',
  'deactivated': 'Powiadomienie zostało usunięte!',
  'sent': 'Na podaną skrzynkę e-mail została wysłana wiadomość z linkiem aktywacyjnym. W przypadku braku wiadomości sprawdź folder "Spam" lub spróbuj ponownie.',
  'error': 'Wystąpił problem podczas dodawania subskrypcji'
}

const SOURCES = {
  'search': 'Wyszukiwarka (Google, Bing itp.)',
  'facebook': 'Facebook',
  'social': 'Inne medium społecznościowe',
  'forum': 'Forum internetowe',
  'friend': 'Znajomi',
  'ad': 'Reklama'
}

function App() {
  const [email, setEmail] = useState('')
  const [model, setModel] = useState('')
  const [source, setSource] = useState('')
  const [modelList, setModelList] = useState([])
  const [message, setMessage] = useState(null)

  const fetchData = async () => {
    try {
      const response = await fetch('https://app.teslahub.pl/api/models')
      const modelList = await response.json()
      setModelList(modelList['models'])
    } catch (error) {
      console.error('Error fetching JSON:', error)
    }
  }

  const subscribe = async (email, model) => {
    try {
      const response = await fetch(`https://app.teslahub.pl/api/subscribe?email=${email}&model=${model}&source=${source}`)
      await response.text()

      setMessage('sent')
    } catch (error) {
      console.error('Error fetching JSON:', error)
      setMessage('error')
    }
  }


  useEffect(() => {
    if (window.location.search.includes('?message=')) {
      setMessage(window.location.search.split('=')[1])
      setTimeout(() => {
        window.location.replace('https://teslahub.pl')
      }, 5000)
    }
    fetchData()
  }, [])


  return (
    <HashRouter>
    <Routes>
      <Route path="/" element={
        <>
      <div class="center-screen">
        <div className={'filters-box'}>
          {message !== null && (
            <div class="message">
              {`${MESSAGES[message]}${message != 'sent' ? ' Za chwilę zostaniesz przekierowany na stronę Teslahub.pl' : ''}`}
            </div>
          )}
          {(message == null || message === 'sent' || message === 'error') && (
            <>
              <div class="form-bg">
                {/* <div class="title">
              Alerty cenowe teslahub.pl
            </div> */}
                <div class="description">
                  <b><center>Zapisz się do darmowych powiadomień o okazjach cenowych Tesli oraz otrzymaj 2000 PLN zniżki na dowolny pojazd!</center></b>
                </div>
                <div className={'filters'}>
                  <div class={'filter'}>
                    <InputLabel style={{ marginBottom: '5px' }}>
                      Twój adres e-mail:
                    </InputLabel>
                    <TextField variant="outlined" value={email} type="email" onChange={(e) => {
                      setEmail(e.target.value.toLowerCase())
                      setMessage(null)
                    }} className={'inputFilter'} />
                  </div>
                  <div class={'filter'}>
                    <InputLabel style={{ marginBottom: '5px' }}>
                      Model samochodu:
                    </InputLabel>
                    <Select variant="outlined" value={model} onChange={(e) => {
                      setModel(e.target.value)
                      setMessage(null)
                    }} className={'selectFilter'}>
                      {modelList.map(({ code, name }) => (
                        <MenuItem value={code}>{name}</MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div class={'filter'}>
                    <InputLabel style={{ marginBottom: '5px' }}>
                      Skąd dowiedziałeś(aś) się o tej stronie:
                    </InputLabel>
                    <Select variant="outlined" value={source} onChange={(e) => {
                      setSource(e.target.value)
                    }} className={'selectFilter'}>
                      {Object.keys(SOURCES).map((code) => (
                        <MenuItem value={code}>{SOURCES[code]}</MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>

              </div>
              <div className={'filter'}>
                <Button disabled={!email || !model || !source} variant="outlined" onClick={() => subscribe(email, model) && setModel('')}>
                  Zapisz się do powiadomień!
                </Button>
              </div>
            </>)}
        </div>
      </div>
      </>}/>
      <Route path='/compare' element={<Compare />}/>
      </Routes>
    </HashRouter>)
}


export default App;

